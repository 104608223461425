'use strict';

var processInclude = require('base/util');


$(document).ready(function () {
    processInclude(require('./product/quickView'));
    processInclude(require('./components/availabilityDial'));
    processInclude(require('./components/lozad'));
    processInclude(require('./components/footerCart'));
});
