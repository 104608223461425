/* eslint-disable require-jsdoc */
'use strict';
var isMobile = !($(window).width() >= 768);
var lottie = require('lottie-web');
var $circularAnimation = $('#loaderAnimation').data('loader');

function circleLoader() {
    $('.js-availability-dial').each(function (index) {
        var $this = $(this);
        var $lot = $this.find('.circular-sold-bar');
        var $soldText = $this.find('.allocation-text.sold-text');
        var $sold = $lot.data('itemsold');
        var $allot = $lot.data('itemallocated');
        var $id = $lot.find('.js-circular-bar-init').attr('data-newid');

        var animation = lottie.loadAnimation({
            container: document.getElementById($id),
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: $circularAnimation,
            name: 'textAnim' + $id
        });

        var colorPer = ($sold / $allot) * 100 > 0 && ($sold / $allot) * 100 <= 3 ? 3 : ($sold / $allot) * 100;
        // $per = $per > 90 && $per < 99 ? 90 : $per;
        colorPer = Math.round(colorPer);
        colorPer = colorPer > 93 && colorPer < 100 ? 93 : colorPer <= 1 ? 1 : colorPer > 100 ? 100 : colorPer;
        var $per = Math.round((240 / 100) * colorPer);


        if (colorPer >= 0 && colorPer <= 30) {
            $lot.addClass('start-campaign');
            $soldText.addClass('start-campaign');
        } else if (colorPer >= 31 && colorPer <= 60) {
            $lot.addClass('medium-campaign');
            $soldText.addClass('medium-campaign');
        } else if (colorPer > 60) {
            $lot.addClass('closing-campaign');
            $soldText.addClass('closing-campaign');
        }
        animation.goToAndStop($per, true);
    });
}

$('body').on('livecampaign:loaded', function (e) {
    circleLoader();
});

if ($('.pdp-new').length > 0) {
    circleLoader();
}
if ($('.cart-page').length > 0) {
    if (!isMobile) {
        circleLoader();
    }
}
if ($('.wishlist').length > 0) {
    if (!isMobile) {
        circleLoader();
    }
}
