'use strict';
module.exports = {
    miniCartIcon: function () {
        $(document).on('product:afterAddToCart', function (e, data) {
            if (
                (data.hasOwnProperty('numItems') && data.numItems > 0) || 
                (data.hasOwnProperty('cart') && data.cart.numItems > 0) || 
                (data.hasOwnProperty('basket') && data.basket.numItems > 0)) {
                $('.js-mini-cart-icon').addClass('cart-full');
            } else {
                $('.js-mini-cart-icon').removeClass('cart-full');
            }
        })
    }
}
